<i18n>
ru:
  gram: г
  kilogram: кг
  liter: л
  piece: шт
  portion: 'порция | порций'
ua:
  gram: г
  kilogram: кг
  liter: л
  piece: шт
  portion: 'порций | порция | порций'
us:
  gram: g
  kilogram: kg
  liter: l
  piece: 'item | items'
  portion: 'portions | portion | portions'
</i18n>

<template>
  <span
    itemscope
    itemprop="weight"
    itemtype="https://schema.org/QuantitativeValue"
  >
    <meta
      :content="commonCode"
      itemprop="unitCode"
    />
    <meta
      :content="`${quantity}`"
      itemprop="value"
    />
    <common-tweened-number
      :num="quantity ?? 0"
      :type="type"
    />&nbsp;{{ measureComputed }}
  </span>
</template>

<script setup lang="ts">
import type { NumericType } from '~types/common'

import { ProductMeasureType } from '~api/consts'

const { measure = 0, quantity = ProductMeasureType.Undefined } = defineProps<{
  quantity?: number
  measure?: ProductMeasureType
}>()

const { translate } = useI18nSanitized()

const type = computed<NumericType>(() => {
  switch (measure) {
    case ProductMeasureType.Kilogram: {
      return 'nutritional'
    }
    case ProductMeasureType.Liter: {
      return 'nutritional'
    }
    case ProductMeasureType.Piece: {
      return 'int'
    }
    case ProductMeasureType.Portion: {
      return 'int'
    }
    default: {
      return 'nutritional'
    }
  }
})
const measureComputed = computed<string>(() => {
  switch (measure) {
    case ProductMeasureType.Kilogram: {
      return translate('quantityMeasure.kilogram', null, quantity)
    }
    case ProductMeasureType.Liter: {
      return translate('quantityMeasure.liter', null, quantity)
    }
    case ProductMeasureType.Piece: {
      return translate('quantityMeasure.piece', null, quantity)
    }
    case ProductMeasureType.Portion: {
      return translate('quantityMeasure.portion', null, quantity)
    }
    default: {
      return translate('quantityMeasure.gram', null, quantity)
    }
  }
})

//UN/CEFACT Common Code
const commonCode = computed<string>(() => {
  switch (measure) {
    case ProductMeasureType.Kilogram: {
      return 'KGM'
    }
    case ProductMeasureType.Liter: {
      return 'LTR'
    }
    case ProductMeasureType.Piece:
    case ProductMeasureType.Portion: {
      return 'C62'
    }
    default: {
      return 'GRM'
    }
  }
})
</script>
